import interceptor from './interceptor.js';

class Request {
    // 对象深度合并
    deepObjectMerge = (target, source) => {
        for (const key in source) {
            if (key.hasOwnProperty) {
                target[key] =
                    target[key] && typeof target[key] === 'object'
                        ? this.deepObjectMerge(target[key], source[key])
                        : (target[key] = source[key]);
            }
        }
        return target;
    };

    // 主要请求部分
    request(options = {}) {
        // 合并配置
        options.dataType = options.dataType || this.config.dataType;
        options.responseType = options.responseType || this.config.responseType;
        options.url = options.url || '';
        options.params = options.params || {};
        options.header = Object.assign({}, this.config.header, options.header);
        options.method = options.method || this.config.method;
        // const CancelToken = interceptor.CancelToken;
        // const source = CancelToken.source();
        // console.log(source.token);
        return new Promise((resolve, reject) => {
            interceptor.request(options).then(response => {
                resolve(response);
            }
            ).catch(err => {
                reject(err);
            })
        });

    }
    constructor() {
        this.config = {
            baseURL: '',
            header: {},
            method: 'POST',
            dataType: 'json',
            responseType: 'text',
            originalData: false,
        }

        // get请求
        this.get = (url, data = {}, header = {}) => {
            return this.request({
                method: 'GET',
                url,
                header,
                data
            })
        }

        // post请求
        this.post = (url, data = {}, header = {}) => {
            return this.request({
                url,
                method: 'POST',
                header,
                data
            })
        }

        // put请求，不支持支付宝小程序(HX2.6.15)
        this.put = (url, data = {}, header = {}) => {
            return this.request({
                url,
                method: 'PUT',
                header,
                data
            })
        }

        // delete请求，不支持支付宝和头条小程序(HX2.6.15)
        this.delete = (url, data = {}, header = {}) => {
            return this.request({
                url,
                method: 'DELETE',
                header,
                data
            })
        }
    }
}

export default new Request();
