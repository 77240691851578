import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import user from './modules/user.js'
import search from './modules/search.js'
import history from './modules/history.js'

export default createStore({
    modules: {
        user,
        search,
        history,
    },
    plugins: [createPersistedstate({ storage: window.sessionStorage })]
})