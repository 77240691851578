import "core-js/modules/es.array.push.js";
import axios from 'axios';
import store from '@/store';
import router from "@/router/route";
import yggConfig from '@/lib/config';
const interceptor = axios.create();
const pending = [];
let loadingInstance = null;
function showLoading() {
  // eslint-disable-next-line valid-typeof
  if (typeof loadingInstance === null) {
    // eslint-disable-next-line no-undef
    loadingInstance = ElLoading.service({
      lock: true,
      text: "Loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
  }
}
function closeLoading() {
  if (loadingInstance != null) {
    loadingInstance.close();
    loadingInstance = null;
  }
}
// 添加请求拦截器
interceptor.interceptors.request.use(function (config) {
  const token = store.state.user.token;
  config.headers = {
    "content-type": "application/json;charset=UTF-8"
  };
  config.data.token = token;
  if (yggConfig.debug) {
    config.url = '/test/' + config.url;
  } else {
    if (config.url.indexOf('/app/') === -1) {
      config.url = '/api/' + config.url;
    }
  }
  if (config.data.verify_login) {
    const user_id = store.state.user.user_id;
    if (!user_id && !token) {
      store.commit('user/changeStatus', true);
      // eslint-disable-next-line no-undef
      ElNotification({
        title: '呱呱提示',
        message: '同学，你还没有登录呢！',
        type: 'error'
      });
      return false;
    }
    config.data.uid = user_id;
    delete config.data.verify_login;
  }
  if (pending.indexOf(config.url) >= 0) {
    return new Promise(() => {});
  }
  pending.push(config.url);
  showLoading();
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 添加响应拦截器
interceptor.interceptors.response.use(function (response) {
  closeLoading();
  pending.splice(pending.indexOf(response.config.url), 1);
  if (response.status == 200) {
    //请求成功
    const data = JSON.parse(response.data);
    if (data.code === 200) {
      return data.data;
    }
    //操作失败
    if (data.code === 502) {
      // eslint-disable-next-line no-undef
      ElNotification({
        title: '呱呱提示',
        message: data.msg,
        type: 'error'
      });
      if (data.data) {
        return Promise.reject(data.data);
      }
      return new Promise(() => {});
    }
    if (data.code === 503) {
      // eslint-disable-next-line no-undef
      ElMessageBox({
        title: data.msg,
        dangerouslyUseHTMLString: true,
        message: data.data,
        center: true,
        callback: () => {}
      });
      return new Promise(() => {});
    }
    // 操作失败返回上一层
    if (data.code === 505) {
      // eslint-disable-next-line no-undef
      ElNotification({
        title: '呱呱提示',
        message: data.msg,
        type: 'error'
      });
      router.back();
      return new Promise(() => {});
    }
    if (data.code === 201) {
      store.dispatch("user/logout");
      store.commit('user/changeStatus', true);
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        ElNotification({
          title: '呱呱提示',
          message: data.msg,
          type: 'warning'
        });
      }, 300);
      return new Promise(() => {});
    }
    return data;
  } else {
    return new Promise(() => {});
  }
  // eslint-disable-next-line no-unused-vars
}, function (_error) {
  closeLoading();
  return new Promise(() => {});
  // return Promise.reject(error);
});
export default interceptor;