import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import './assets/css/css-vars.css'
import App from './App.vue'
import router from './router/route.js'
import store from './store'
import http from './lib/request/request.js'
import api from './lib/request/api.js'
import yggConfig from './lib/config'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$ygg = {
  http,
  get: http.get,
  post: http.post,
  put: http.put,
  'delete': http.delete,
  'config': yggConfig,
}
app.use(api)
app.use(router)
app.use(store)
app.use(Vue3VideoPlayer, {
  lang: 'zh-CN'
})
// app.use({ locale: zhCn })
app.mount('#app')
