export default {
    // 开启命名空间
    namespaced: true,
    state: {
        route: []
    },
    mutations: {
        pushHistoryRoute(state, data) {
            state.route.push(data);
        },
        deleteHistoryRoute(state, path) {
            state.route = state.route.filter(item => {
                return item.path != path
            })
        },
        clearHistoryRoute(state) {
            state.route = []
        }

    },
    actions: {
        push({ commit }, params) {
            commit('deleteHistoryRoute', params.path);
            commit('pushHistoryRoute', params);
        },
        delete({ commit }, path) {
            commit('deleteHistoryRoute', path);
        },
        clear({ commit }) {
            commit('clearHistoryRoute');
        }
    },
}