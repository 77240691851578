import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/index",
        name: "index",
        component: () => import(/* webpackChunkName: "Index" */ "@/pages/home/Index.vue"),
        redirect: '/home',
        children: [
            {
                path: "/home",
                name: "首页",
                component: () => import(/* webpackChunkName: "Home" */ "@/pages/home/Home.vue")
            },
            {
                path: "/TeacherTeam",
                name: "名师团队",
                component: () => import(/* webpackChunkName: "TeacherTeam" */ "@/pages/home/TeacherTeam.vue")
            }, {
                path: "/courseme",
                name: "我的课程",
                component: () => import(/* webpackChunkName: "CourseMe" */ "@/pages/course/CourseMe.vue"),
            }, {
                path: "/CourseIntroduce",
                name: "courseintroduce",
                component: () => import(/* webpackChunkName: "CourseIntroduce" */ "@/pages/course/CourseIntroduce.vue"),
            },
            {
                path: "/mysubscription",
                name: "我的订阅",
                component: () => import(/* webpackChunkName: "MySubscription" */ "@/pages/course/MySubscription.vue")
            },
            {
                path: "/history",
                name: "课程历史记录",
                component: () => import(/* webpackChunkName: "History" */ "@/pages/home/History.vue")
            },
            {
                path: "/about",
                name: "关于我们",
                component: () => import(/* webpackChunkName: "About" */ "@/pages/home/About.vue")
            },
            {
                path: "/userinfo",
                name: "用户信息",
                component: () => import(/* webpackChunkName: "UserInfo" */ "@/pages/home/UserInfo.vue")
            },
            {
                path: "/collegeToLibrary",
                name: "collegeToLibrary",
                component: () => import(/* webpackChunkName: "collegeToLibrary" */ "@/pages/library/college/Home.vue")
            },
            {
                path: "/collegeToLibraryEasy",
                name: "collegeToLibraryEasy",
                component: () => import(/* webpackChunkName: "collegeToLibraryEasy" */ "@/pages/library/college/Easy.vue")
            },
            {
                path: "/collegeToLibraryLog",
                name: "collegeToLibraryLog",
                component: () => import(/* webpackChunkName: "collegeToLibraryLog" */ "@/pages/library/college/Log.vue")
            },
            {
                path: "/collegeToLibraryReport",
                name: "collegeToLibraryReport",
                component: () => import(/* webpackChunkName: "collegeToLibraryReport" */ "@/pages/library/college/Report.vue")
            },
            {
                path: "/manually",
                name: "人工答疑",
                component: () => import(/* webpackChunkName: "manually" */ "@/pages/service/manually/index.vue"),
                redirect: '',
                children: [
                    {
                        path: "/manuallyHome",
                        name: "人工答疑",
                        component: () => import(/* webpackChunkName: "manuallyHome" */ "@/pages/service/manually/home.vue")
                    },
                    
                    {
                        path: "/manuallySubmit",
                        name: "manuallySubmit",
                        component: () => import(/* webpackChunkName: "manuallySubmit" */ "@/pages/service/manually/submit.vue")
                    },
                    
                    {
                        path: "/manuallyChat",
                        name: "manuallyChat",
                        component: () => import(/* webpackChunkName: "manuallyChat" */ "@/pages/service/manually/chat.vue")
                    }
                ]
            },
        ]
    },
    {
        path: "/course",
        name: "course",
        component: () => import(/* webpackChunkName: "Course" */ "@/pages/course/Course.vue"),
        redirect: "",
        children: [
            {
                path: "/courselist",
                name: "courselist",
                component: () => import(/* webpackChunkName: "CourseList" */ "@/pages/course/CourseList.vue"),
            },
            {
                path: "/coursecard",
                name: "coursecard",
                component: () => import(/* webpackChunkName: "CourseCard" */ "@/pages/course/CourseCard.vue"),
            },
            {
                path: "/coursedetail",
                name: "coursedetail",
                component: () => import(/* webpackChunkName: "CourseDetail" */ "@/pages/course/CourseDetail.vue"),
            }
        ],
    },
    {
        path: "/library",
        name: "library",
        component: () => import(/* webpackChunkName: "Course" */ "@/pages/library/library.vue"),
        redirect: "",
        children: [
            {
                path: "/adjustToLibrary",
                name: "adjustToLibrary",
                component: () => import(/* webpackChunkName: "adjustToLibrary" */ "@/pages/library/adjust/Home.vue")
            },
            {
                path: "/adjustSubscribe",
                name: "adjustSubscribe",
                component: () => import(/* webpackChunkName: "adjustSubscribe" */ "@/pages/library/adjust/Subscribe.vue")
            },
            {
                path: "/adjustSubscribeInfo",
                name: "adjustSubscribeInfo",
                component: () => import(/* webpackChunkName: "adjustSubscribeInfo" */ "@/pages/library/adjust/Info.vue")
            },
        ],
    },
    {
        path: "/service",
        name: "VIP服务",
        component: () => import(/* webpackChunkName: "service" */ "@/pages/service/buffet/selfService.vue"),
        redirect: "",
        children: [
            {
                path: "/selfServiceList",
                name: "自助答疑",
                component: () => import(/* webpackChunkName: "selfServiceList" */ "@/pages/service/buffet/list.vue")
            },
            {
                path: "/selfServiceFavorList",
                name: "收藏列表",
                component: () => import(/* webpackChunkName: "selfServiceFavorList" */ "@/pages/service/buffet/favor.vue")
            },
            {
                path: "/selfServiceInfo",
                name: "图书列表",
                component: () => import(/* webpackChunkName: "selfServiceInfo" */ "@/pages/service/buffet/info.vue")
            },
            {
                path: "/selfServiceVideo",
                name: "自助视频",
                component: () => import(/* webpackChunkName: "selfServiceVideo" */ "@/pages/service/buffet/video.vue")
            }
        ],
    },
    {
        path: '/Merchants',
        name: '招商加盟',
        component: () => import(/* webpackChunkName: "Merchants" */ "@/pages/aboutUs/Merchants.vue")
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {
    // const routeList = ['/home', '/about', '/TeacherTeam'];
    // console.log(to)
    // store.dispatch("history/delete", from.path);
    // store.dispatch("history/push", { path: to.path, name: to.name, query: to.query });
    // next();
    // if (routeList.indexOf(to.path) > -1) {
    //     next();
    // } else {
    //     const token = store.state.user.token;
    //     if (token === null || token === '') {
    //         store.commit('user/changeStatus', true);

    //         setTimeout(() => {
    //             ElMessage.error("您还没有登录，请先登录");
    //         }, 300);
    //     } else {
    //         next();
    //     }
    // }
// });

export default router