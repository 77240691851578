export default {
    // 开启命名空间
    namespaced: true,
    state: {
        course: null
    },
    mutations: {
        setCourse(state, search) {
            state.course = search;
        },
    },
}