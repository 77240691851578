export default {
    // 开启命名空间
    namespaced: true,
    state: {
        info: null,
        token: null,
        user_id: 0,
        login_status: false,
        CourseSearch: null,
        code: 3
    },
    mutations: {
        changeStatus(state, status) {
            state.login_status = status;
        },
        setInfo(state, user) {
            state.info = user;
        },
        clearInfo(state) {
            state.info = null;
        },
        setToken(state, token) {
            state.token = token;
        },
        clearToken(state) {
            state.token = null;
        },
        setUserId(state, user_id) {
            state.user_id = user_id;
        },
        clearUserId(state) {
            state.user_id = 0;
        }
    },
    actions: {
        // 登录
        login({ commit }, user) {
            commit('setInfo', user);
            commit('setToken', user.token);
            commit('setUserId', user.id);
        },

        // 编辑用户信息
        editUser({ commit }, user) {
            commit('setInfo', user);
        },

        // 退出登录
        logout({ commit }) {
            commit('clearInfo');
            commit('clearToken');
            commit('clearUserId');
        },
    },
}